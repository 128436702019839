import React, { Fragment } from "react";
import TextBlock from "../Blocks/TextBlock";
import ImageBlock from "../Blocks/ImageBlock";
import * as styles from "./Ending.module.scss";

const Ending = ({ ending }: any) => {
    return (
        ending && (
            <section className={styles.wrapper} style={{ "--studyTheme": "#FFF" } as React.CSSProperties}>
                <div className={`${styles.inner} mainBox`}>
                    {ending.map(
                        (
                            end: {
                                type: string;
                                heading: any;
                                paragraphHtml: any;
                                paragraphHTML: any;
                                image: any;
                                imageObject: any;
                                url: any;
                            },
                            i: React.Key | null | undefined
                        ) => (
                            <Fragment key={i}>
                                {end.type.toLowerCase() === "text" && <TextBlock heading={end.heading} paragraph={end.paragraphHTML} boxWidth="75" />}

                                {end.type.toLowerCase() === "image" && end.image && <ImageBlock imageObject={end.image} boxWidth="75" />}
                            </Fragment>
                        )
                    )}
                </div>
            </section>
        )
    );
};

export default Ending;
