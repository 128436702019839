import React, { Fragment } from "react";
import TextAndImageBlock from "../Blocks/TextAndImageBlock";
import TextBlock from "../Blocks/TextBlock";
import ImageBlock from "../Blocks/ImageBlock";
import * as styles from "./StudiesBody.module.scss";

const StudiesBody = ({ theme, body }: any) =>
    body && (
        <section className={styles.wrapper} style={{ "--studyTheme": theme } as React.CSSProperties}>
            <div className="wideBox">
                {body.map(
                    (
                        study: {
                            type: string;
                            imageAlignment: any;
                            heading: any;
                            paragraphHTML: any;
                            image: any;
                        },
                        i: React.Key | null | undefined
                    ) => (
                        <Fragment key={i}>
                            {study.type.toLowerCase() === "text_and_image" && (
                                <TextAndImageBlock
                                    imageAlignment={study.imageAlignment}
                                    heading={study.heading}
                                    paragraph={study.paragraphHTML}
                                    imageObject={study.image}
                                />
                            )}
                            {study.type.toLowerCase() === "text" && <TextBlock heading={study.heading} paragraph={study.paragraphHTML} boxWidth="60" />}
                            {study.type.toLowerCase() === "image" && study.image && (
                                <div className={styles.imageWrapper}>
                                    <ImageBlock image={study.image} imageObject={study.image} boxWidth="55" />
                                </div>
                            )}
                        </Fragment>
                    )
                )}
            </div>
        </section>
    );

export default StudiesBody;
