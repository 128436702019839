import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import * as styles from "./ImageBlock.module.scss";

const ImageBlock = ({ imageObject, boxWidth }: any) => {
    return (
        <div
            className={`${styles.studiesImageWrapper} ${styles.imgLoaded}`}
            style={
                {
                    "--studyAspectRatio":
                        (imageObject?.file.childImageSharp.gatsbyImageData.height / imageObject?.file.childImageSharp.gatsbyImageData.width) * 100,
                    "--imageBoxWidth": boxWidth,
                    "--aspectRatioCalc":
                        (imageObject?.file.childImageSharp.gatsbyImageData.height / imageObject?.file.childImageSharp.gatsbyImageData.width) * boxWidth,
                } as React.CSSProperties
            }>
            <GatsbyImage
                className={`${styles.studiesImage} ${styles.main}`}
                alt={imageObject.alternativeText || "software development company"}
                image={imageObject.file.childImageSharp.gatsbyImageData}
            />
        </div>
    );
};

export default ImageBlock;
