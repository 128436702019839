import React from "react";
import * as styles from "./Hero.module.scss";

const Hero = (props: any) => {
    const { heading, paragraphHTML, theme } = props;

    return (
        <section className={styles.wrapper}>
            <div className={`${styles.inner} mainBox`}>
                <h1 style={{ color: theme }}>{heading}</h1>
                <div
                    className={styles.paragraph}
                    dangerouslySetInnerHTML={{
                        __html: paragraphHTML,
                    }}></div>
            </div>
        </section>
    );
};

export default Hero;
