// extracted by mini-css-extract-plugin
export var gatsbyImageWrapper = "TextAndImageBlock-module--gatsby-image-wrapper--1f1af";
export var imgLoaded = "TextAndImageBlock-module--imgLoaded--734cb";
export var left = "TextAndImageBlock-module--left--c413b";
export var main = "TextAndImageBlock-module--main--627a9";
export var placeholder = "TextAndImageBlock-module--placeholder--f933b";
export var revealImage = "TextAndImageBlock-module--reveal-image--73dbc";
export var studiesImage = "TextAndImageBlock-module--studiesImage--696d4";
export var studiesImageWrapper = "TextAndImageBlock-module--studiesImageWrapper--2e1f7";
export var studiesParagraph = "TextAndImageBlock-module--studiesParagraph--3055d";
export var subHeading = "TextAndImageBlock-module--subHeading--1bc85";
export var textWrapper = "TextAndImageBlock-module--textWrapper--d9d9c";
export var wrapper = "TextAndImageBlock-module--wrapper--9af61";