import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import * as styles from "./TextAndImageBlock.module.scss";

interface ITextAndImageBlock {
    imageAlignment: string;
    heading?: string | null;
    paragraph?: string | null;
    image?: any;
    imageObject?: any;
}

const TextAndImageBlock = (props: ITextAndImageBlock) => {
    const { imageAlignment, heading, paragraph, imageObject } = props;

    return (
        <div className={`${styles.wrapper} ${styles[imageAlignment.toLowerCase()]}`}>
            <div className={styles.textWrapper}>
                {heading && <h1 className={styles.subHeading}>{heading}</h1>}

                {paragraph && <div className={styles.studiesParagraph} dangerouslySetInnerHTML={{ __html: paragraph }} />}
            </div>
            {imageObject && (
                <div
                    className={`${styles.studiesImageWrapper} ${styles.imgLoaded}`}
                    style={
                        {
                            "--studyAspectRatio":
                                (imageObject?.file.childImageSharp.gatsbyImageData.height / imageObject?.file.childImageSharp.gatsbyImageData.width) * 100,
                        } as React.CSSProperties
                    }>
                    <GatsbyImage
                        alt={imageObject.alternativeText || "software development company"}
                        image={imageObject.file.childImageSharp.gatsbyImageData}
                        className={`${styles.studiesImage} ${styles.main}`}
                        key={imageObject?.url}
                    />
                </div>
            )}
        </div>
    );
};

export default TextAndImageBlock;
