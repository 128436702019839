import React, { ReactElement } from "react";
import Hero from "./Hero";
import StudiesBody from "./StudiesBody";
import Ending from "./Ending";
import { graphql } from "gatsby";
import { SiteMetadataHead } from "../../components/Shared/SiteMetadataHead";

interface IStudiesProps {
    data: any;
}

const StudiesPageTemplate = ({ data }: IStudiesProps): ReactElement => {
    const { backgroundStyle, body, ending, hero } = data.strapiCaseStudiesPage;

    return (
        <>
            <Hero theme={backgroundStyle} {...hero} />
            <StudiesBody theme={backgroundStyle} {...{ body }} />
            <Ending {...{ ending }} />
        </>
    );
};

export default StudiesPageTemplate;

export const query = graphql`
    query Studies($id: String!) {
        strapiCaseStudiesPage(id: { eq: $id }) {
            backgroundStyle
            hero {
                heading
                paragraphHTML
            }
            ending {
                imageAlignment
                paragraphHTML
                id
                heading
                type
                image {
                    alternativeText
                    file {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
            body {
                paragraphHTML
                imageAlignment
                heading
                type
                image {
                    alternativeText
                    file {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
        allStrapiCaseStudiesPage {
            nodes {
                backgroundStyle
                hero {
                    heading
                    paragraphHTML
                }
                ending {
                    heading
                    id
                    imageAlignment
                    paragraphHTML
                    type
                    image {
                        alternativeText
                        file {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
                body {
                    paragraphHTML
                    imageAlignment
                    heading
                    type
                    image {
                        alternativeText
                        file {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        }
    }
`;

export function Head({ pageContext }: any) {
    const { seo } = pageContext;

    return (
        <SiteMetadataHead>
            <title id='site-title'>{seo.title}</title>
            <meta id='desc' name="description" content={seo.description} />
            <meta id='tw-card' name="twitter:card" content={seo.twitter.card } />
            <meta id='tw-site' name="twitter:site" content={seo.twitter.site} />
            <meta id='tw-creator' name="twitter:creator" content={seo.twitter.creator } />
            <meta id='tw-title' name="twitter:title" content={seo.twitter.title } />
            <meta id='tw-desc' name="twitter:description" content={seo.twitter.description } />
            <meta id='tw-img' name="twitter:image" content={seo.twitter.image.url } />
            <meta id='fb-type' property="og:type" content={seo.facebook.type } />
            <meta id='fb-title' property="og:title" content={seo.facebook.title } />
            <meta id='fb-desc' property="og:description" content={seo.facebook.description } />
            <meta id='fb-url' property="og:url" content={seo.facebook.url } />
            <meta id='fb-img' property="og:image" content={seo.facebook.image.url } />
        </SiteMetadataHead>
    );
}