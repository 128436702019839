import React from "react";
import * as styles from "./TextBlock.module.scss";

const TextBlock = ({ heading, paragraph, boxWidth }: any) => {
    return (
        <div className={styles.studiesText} style={{ "--textBoxWidth": boxWidth } as React.CSSProperties}>
            {heading && <h1 className={styles.subHeading}>{heading}</h1>}

            {paragraph && <div dangerouslySetInnerHTML={{ __html: paragraph }} />}
        </div>
    );
};

export default TextBlock;
